<template>
	<div class="outer-wrapper filters"> 

		<div id="top-nav"> 
			<router-link :to="{ name: 'Tours Filters' }">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>
	
		<div class="inner-wrapper main"> <!-- Inner wrapper handles global padding -->

			<div class="heading-section">
				<img class="icon" width="24px" src="@/assets/filters/duration.png" />
				<h1 class="heading6">Duration</h1>
			</div>

			<RangeSlider 
				v-if="searchFilters.duration"
				
				class="slider"
				:minValue="filterOptions.duration.low"
				:maxValue="filterOptions.duration.high"
				:formatFunction="daysStringFormat"
				v-model="selectedDurationRange"

				@change="update()"
			/>

		</div>
			
		<div class="bottom inner-wrapper">
			<router-link :to="{ name: 'Tours Filters' }">
				<button class="button">Continue</button>
			</router-link>
		</div>
	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import helpers from '@/helpers/helpers.js';
import RangeSlider from '@/components/RangeSlider.vue';
import router from '@/router';

export default {
    data () {
        return {
			selectedDurationRange: { low: false, high: false },
        }
	},
	components: {
		RangeSlider,
	},
   computed: {
		...mapState({
			searchFilters: state => state.toursSearch.searchFilters,
			searchResults: state => state.toursSearch.searchResults,
			searchValues: state => state.toursSearch.searchValues,
		}),
		...mapGetters({
			filterOptions: 'toursSearch/getFilterOptions',
		})
	},
    methods: {
		formatSecondsAsTime: (seconds) => helpers.formatSecondsAsTime(seconds),
		daysStringFormat(days) {
			if(days > 1) {
				return `${days} days`;
			} else {
				return `${days} day`;
			}
		},
		update() { // If the selected price range == max price ranges, then turn filter off
			this.searchFilters.duration.low = this.selectedDurationRange.low;
			this.searchFilters.duration.high = this.selectedDurationRange.high;
			if(this.filterOptions.duration.low == this.selectedDurationRange.low && this.filterOptions.duration.high == this.selectedDurationRange.high) {
				this.searchFilters.duration.low = false;
				this.searchFilters.duration.high = false;
			}
		}
	},
	created () {
		if(this.searchResults.length == 0) { // Probably a page refresh - redirect to results page (with query params)
			router.push({
				name: 'Tours Results',
			})
		}
		this.selectedDurationRange = this.searchFilters.duration;
	},
	
};
</script>


<style scoped>
	.heading-section {
		text-align: left;
	}

	.slider {
		margin-top: 20px;
	}

	.return {
		margin-top: 50px;
	}
	
</style>